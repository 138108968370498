module.exports = {
  key: {
    storage: {
      current_account: "CURRENT_USER",
      data_menu: "DATA_MENU",
      data_user_email: "DATA_USER_EMAIL",
      data_hospital: "DATA_HOSPITAL",
      data_topic: "DATA_TOPIC",
      data_location: "DATA_LOCATION",
      data_positions: "DATA_POSITIONS",
      data_speciality: "DATA_SPECIALITY",
      data_unit: "DATA_UNIT",
      data_user: "DATA_USER",
      data_city: "DATA_CITY",
      data_setting: "DATA_SETTING",
    },
  },
  action: {
    action_user_login: "ACTION_USER_LOGIN",
    action_user_logout: "ACTION_USER_LOGOUT",
    action_user_user_email: "ACTION_USER_USER_EMAIL",
    update_avatar: "UPDATE-AVATAR",
    update_notification_count: "UPDATE_NOTIFICATION_COUNT",
    update_tabs_key: "UPDATE_TABS_KEY",
    UPDATE_LOADING: "UPDATE_LOADING",
    RENDER_SIDEBAR: "RENDER_SIDEBAR",
  },
  api: {
    files: {
      file: "/files/file",
      image: "/files/image",
      files: "/files/files",
      images: "/files/images",
    },
    auth: "/auth/login",
    user: "/users",
    unit: "/units",
    unit_coordination: "/coordination-units",
    facultys: "/facultys",
    location: "/locations",
    positions: "/positions",
    infomationTopic: "/topics",
    topic_types: "/topic-types",
    progresses: "/progresses",
    recordReports: "/record_reports",
    file: "/files/file",
    image: "/files/image",
    acceptances: "/acceptances",
    hospital: "/hospitals",
    cities: "/cities",
    setting: "/thiet-lap",
    sendOtp: "/users/gui-email-otp-xac-thuc",
    otpAuthen: "/users/kiem-tra-otp-hop-le",
    forgotPassword: "/users/forgot-password",
    guidelineRegister: "/huong-dan-dang-ky-de-tai",
    notification: "/thong-bao",
    countMissingNotification: "/thong-bao/so-luong-thong-bao-chua-doc",
    adminCreateNotification: "/thong-bao/thong-bao-tu-tao",
    isReader: "/thong-bao/da-doc",
    distinctEmail: "/users/danh-sach-email-nguoi-dung",
    status: "/dm-trang-thai-de-tai",
    dashboard: {
      thongKeTheoTrangThai:
        "/dashboard/thong-ke-so-luong-de-tai/theo-trang-thai",
      thongKeTheoNamThang: "/dashboard/thong-ke-so-luong-de-tai/theo-nam-thang",
    },
    council: "/dm-hoi-dong",
    topicRole: "/dm-vai-tro",
    fileHistory: "/dm-file-history",
    logTopic: "/log-de-tai",
    hisPatient: "/dm-his-nb-de-tai",
    reportTopic:"/bao-cao-de-tai"
  },
};
