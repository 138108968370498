import React, { useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import userProvider from "./data-access/user-provider";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import constants from "./resources/strings";
import { BrowserRouter } from "react-router-dom";
import Loadable from "react-loadable";
import dataCacheProvider from "@data-access/datacache-provider";
import setTingProvider from "@data-access/setting-provider";
import "./style.scss";
import "antd/dist/antd.css";
import { Spin } from "antd";
import styled from "styled-components";
function Loading() {
  return <div></div>;
}
const routes = [
  {
    path: "/list-topic",
    component: Loadable({
      loader: () => import("./sites/user/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: "/them-moi",
    component: Loadable({
      loader: () => import("./sites/user/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: "/topic/:id",
    component: Loadable({
      loader: () => import("./sites/user/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: "/admin",
    component: Loadable({
      loader: () => import("./sites/admin/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: "/admin/:function",
    component: Loadable({
      loader: () => import("./sites/admin/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: "/admin/:function/:id",
    component: Loadable({
      loader: () => import("./sites/admin/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: "/admin/:function/:function1/:id",
    component: Loadable({
      loader: () => import("./sites/admin/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: "/user-info",
    component: Loadable({
      loader: () => import("./sites/admin/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: "/thong-tin-tai-khoan",
    component: Loadable({
      loader: () => import("./sites/user/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: "/thong-tin-de-tai",
    component: Loadable({
      loader: () => import("./sites/user/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: "/nguoi-tham-gia",
    component: Loadable({
      loader: () => import("./sites/user/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: "/tien-do-thuc-hien",
    component: Loadable({
      loader: () => import("./sites/user/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: "/ghi-nhan-bao-cao",
    component: Loadable({
      loader: () => import("./sites/user/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: "/nghiem-thu-de-tai",
    component: Loadable({
      loader: () => import("./sites/user/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: "/bao-cao-de-tai",
    component: Loadable({
      loader: () => import("./sites/user/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: "/bao-cao-ket-qua-de-tai",
    component: Loadable({
      loader: () => import("./sites/user/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: "/thong-tin-de-tai/:id",
    component: Loadable({
      loader: () => import("./sites/user/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: "/nguoi-tham-gia/:id",
    component: Loadable({
      loader: () => import("./sites/user/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: "/tien-do-thuc-hien/:id",
    component: Loadable({
      loader: () => import("./sites/user/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: "/ghi-nhan-bao-cao/:id",
    component: Loadable({
      loader: () => import("./sites/user/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: "/nghiem-thu-de-tai/:id",
    component: Loadable({
      loader: () => import("./sites/user/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: "/bao-cao-de-tai/:id",
    component: Loadable({
      loader: () => import("./sites/user/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: "/danh-sach-de-tai",
    component: Loadable({
      loader: () => import("./sites/user/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: ["/print/:id"],
    component: Loadable({
      loader: () => import("@utils/Print"),
      loading: Loading,
    }),
  },
  {
    path: "/",
    component: Loadable({
      loader: () => import("./sites/user/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: "/:function/:id",
    component: Loadable({
      loader: () => import("./sites/user/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: "/dang-nhap",
    component: Loadable({
      loader: () => import("./sites/user/containers/account/Login"),
      loading: Loading,
    }),
  },
  {
    path: ["/dang-ky"],
    component: Loadable({
      loader: () => import("./sites/user/containers/account/Register"),
      loading: Loading,
    }),
  },
  {
    path: ["/guideline-register"],
    component: Loadable({
      loader: () => import("./sites/user/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: ["/forgot-password"],
    component: Loadable({
      loader: () => import("./sites/user/containers/account/ForgotPassword"),
      loading: Loading,
    }),
  },
  {
    path: ["/change-password"],
    component: Loadable({
      loader: () => import("./sites/user/containers/account/ChangePassword"),
      loading: Loading,
    }),
  },
  {
    path: ["/notification"],
    component: Loadable({
      loader: () => import("./sites/user/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: ["/log-topic"],
    component: Loadable({
      loader: () => import("./sites/user/containers/index"),
      loading: Loading,
    }),
  },
  {
    path: ["/topic-report"],
    component: Loadable({
      loader: () => import("./sites/user/containers/index"),
      loading: Loading,
    }),
  },
];
function Main({ isLoading }) {
  let dataSetting =
    dataCacheProvider.read("", constants.key.storage.data_setting) || [];
  let image = dataSetting.find((item) => item.ma === "LOGO_HEADER");
  if (image) {
    var src = document.getElementById("logo-header");
    src.href = image.giaTri && image.giaTri.absoluteUrl();
  }
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    setTingProvider
      .search()
      .then((s) => {
        if (s && s.code === 0 && s.data) {
          if (JSON.stringify(dataSetting) === JSON.stringify(s.data)) {
            return true;
          } else {
            let image =
              s.data &&
              s.data.length &&
              s.data.find((item) => item.ma === "LOGO_HEADER");
            if (image) {
              var src = document.getElementById("logo-header");
              src.href = image.giaTri && image.giaTri.absoluteUrl();
            }
            dataCacheProvider.save(
              "",
              constants.key.storage.data_setting,
              s.data
            );
          }
        }
      })
      .catch(() => {});
  };
  // if (window.location.origin === "http://10.0.80.224:2389") {
  //   src.href = window.location.origin + "/images/logo-phoi.png";
  // } else {
  //   src.href = window.location.origin + "/images/logo/logoIsofhPay01.png";
  // }
  const dispatch = useDispatch();
  dispatch({
    type: constants.action.action_user_login,
    value: userProvider.getAccountStorage(),
  });

  return (
    <BrowserRouter>
      <div className="ykhn">
        {isLoading && <LoadingIndicator />}
        <BrowserRouter>
          <div>
            <Switch>
              {routes.map((route, key) => {
                if (route.component)
                  return (
                    <Route
                      exact
                      key={key}
                      path={route.path}
                      render={(props) => <route.component {...props} />}
                    />
                  );
                return null;
              })}
            </Switch>
          </div>
        </BrowserRouter>
      </div>
    </BrowserRouter>
  );
}
const LoadingDiv = styled.div`
  position: absolute;
  left: 50%;
  right: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
  z-index: 1;
`;
const LoadingIndicator = () => {
  return (
    <LoadingDiv>
      <Spin size="large" />
    </LoadingDiv>
  );
};
export default connect((state) => {
  return { isLoading: state.isLoading };
})(Main);
