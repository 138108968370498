import constants from '../resources/strings';
import clientUtils from '../utils/client-utils';

export default {
  search() {
    let parameters = `?page=0&size=100&sort=createdAt,desc`
    return new Promise((resolve, reject) => {
      clientUtils.requestApi('get', constants.api.setting + parameters, {}).then(x => {
        resolve(x)
      }).catch(e => {
        reject(e)
      })
    })
  },
  createOrUpdate(id, object) {
    return new Promise((resolve, reject) => {
      if (id) {
        clientUtils.requestApi('put', constants.api.setting + '/' + id, object).then(x => {
          resolve(x)
        }).catch(e => {
          reject(e)
        })
      } else {
        clientUtils.requestApi('post', constants.api.setting, object).then(x => {
          resolve(x)
        }).catch(e => {
          reject(e)
        })
      }
    })
  },
  delete(id) {
    return new Promise((resolve, reject) => {
      clientUtils.requestApi('delete', constants.api.setting + '/' + id).then(x => {
        resolve(x)
      }).catch(e => {
        reject(e)
      })
    })
  },
  getDetail(id) {
    return new Promise((resolve, reject) => {
      clientUtils.requestApi('get', constants.api.setting + '/' + id).then(x => {
        resolve(x)
      }).catch(e => {
        reject(e)
      })
    })
  },
}
