import axios from "axios";

const getServerUrl = () => {
  const domain = global.origin;
  switch (domain) {
    case "http://localhost:3002": // test
    return "https://nckh-api.benhviene.com/api/research/v1";
    case "http://10.0.0.94:9101": // test phổi
      return "http://10.0.0.45:1082/api/research/v1";
    case "http://10.0.0.94:9100": // test dhy
      return "http://10.0.0.45:9102/api/research/v1";
    case "http://101.99.20.5:9100": // test phổi
      return "http://101.99.20.5:18888/api/research/v1";
    case "http://10.0.0.94:2102": // test DHY
      return "http://http://10.0.0.94:9102/api/research/v1";
    case "http://103.164.34.10:2389": //production phoi
      return "http://103.164.34.10:8082/api/research/v1";
    case "https://nckh.bvptw.org": //production phoi
      return "https://nckh-api.bvptw.org/api/research/v1";
    case "http://10.0.88.12:2389": // production saint paul
      return "http://10.0.88.12:8082/api/research/v1";
    case "http://192.168.0.242:2389": // //production bve
      return "http://192.168.0.242:8082/api/research/v1";
    case "http://192.1.0.242:2389": // //production bve
      return "http://192.1.0.242:8082/api/research/v1";
    case "http://10.0.1.242:2389": // //production bve
      return "http://10.0.1.242:8082/api/research/v1";
    case "http://118.70.151.18:2389": // //production bve
      return "http://118.70.151.18:8082/api/research/v1";
    case "https://nckh.benhviene.com": // //production bve
      return "https://nckh-api.benhviene.com/api/research/v1";
    case "http://nckh.bvxanhpon.vn": // production saint paul
      return "http://10.0.88.12:8082/api/research/v1";
    case "https://nckh.bvxanhpon.vn": // production saint paul
      return "http://10.0.88.12:8082/api/research/v1";
    case "http://10.0.80.224:2389": // production phổi
      return "http://10.0.0.93:1082/api/research/v1";
    case "http://10.110.102.67:2389": // production QY110
      return "http://10.110.102.11:8082/api/research/v1";
    case "https://nckh.benhvien110.vn": // production QY110 domain
      return "https://api-nckh.benhvien110.vn/api/research/v1";
    case "http://10.0.0.94:9102": //bve develop
      return "http://10.0.0.45:2082/api/research/v1";
    case "http://10.0.0.49:9603": //bve develop
      return "http://10.0.0.49:3082/api/research/v1";
    case "https://nckh-demo.isofh.com": //bve develop
      return "https://api-nckh-demo.isofh.com/api/research/v1";
    default:
      return "https://nckh-api.benhviene.com/api/research/v1";
  }
};
const server_url = getServerUrl();
window.server_url = server_url;

String.prototype.absoluteUrl =
  String.prototype.absolute ||
  function (defaultValue) {
    var _this = this.toString();
    if (_this == "")
      if (defaultValue != undefined) return defaultValue;
      else return _this;
    if (_this.startsWith("http") || _this.startsWith("blob")) {
      return _this;
    }
    if (!_this.startsWith("/")) {
      return server_url + "/files/" + _this;
    }
    if (
      _this.endsWith(".jpg") ||
      _this.endsWith(".png") ||
      _this.endsWith(".JPG") ||
      _this.endsWith(".PNG") ||
      _this.endsWith(".gif")
    ) {
      return server_url + "files/" + _this;
    }
    if (
      !_this.endsWith(".jpg") ||
      !_this.endsWith(".png") ||
      _this.endsWith(".JPG") ||
      _this.endsWith(".PNG") ||
      !_this.endsWith(".gif")
    ) {
      return defaultValue;
    }
    return server_url + "files/" + _this;
  };
String.prototype.getServiceUrl =
  String.prototype.absolute ||
  function (defaultValue) {
    if (this == "")
      if (defaultValue != undefined) return defaultValue;
      else return this;
    if (this.startsWith("http") || this.startsWith("blob")) {
      return this;
    }
    return server_url + this;
  };

export default {
  auth: "",
  serverApi: server_url,
  serverApiDownload: server_url + "/file/download",
  response: {
    ok(data, message) {
      if (!message) message = "";
      return {
        success: true,
        data: data,
        message: message,
      };
    },
    noOk(message) {
      if (!message) message = "";
      return {
        success: false,
        message: message,
      };
    },
  },
  uploadFile(url, file) {
    const formData = new FormData();
    formData.append("file", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: this.auth,
      },
    };
    return axios.post(url.getServiceUrl(), formData, config);
  },
  uploadFiles(url, files) {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: this.auth,
      },
    };
    return axios.post(url.getServiceUrl(), formData, config);
  },
  uploadImage(url, file) {
    const formData = new FormData();
    formData.append("image", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: this.auth,
      },
    };
    return axios.post(url.getServiceUrl(), formData, config);
  },
  requestApi(methodType, url, body) {
    return new Promise((resolve, reject) => {
      var dataBody = "";
      if (!body) body = {};
      dataBody = JSON.stringify(body);
      this.requestFetch(
        methodType,
        url && url.indexOf("http") == 0 ? url : url,
        {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.auth,
        },
        dataBody
      )
        .then((s) => {
          s.json()
            .then((val) => {
              resolve(val);
            })
            .catch((e) => {
              reject(e);
            });
        })
        .catch((e) => {
          if (e && e.status === 401) {
            localStorage.clear();
            window.location.href = "/dang-nhap";
          }
          reject(e);
        })
        .catch((e) => {
          if (e && e.status === 401) {
            localStorage.clear();
            window.location.href = "/dang-nhap";
          }
          reject(e);
        });
    });
  },
  requestFetch(methodType, url, headers, body) {
    return new Promise((resolve, reject) => {
      let fetchParam = {
        method: methodType,
        headers,
      };

      if (methodType.toLowerCase() !== "get") {
        fetchParam.body = body;
      }
      return fetch(url.getServiceUrl(), fetchParam)
        .then((json) => {
          if (!json.ok) {
            reject(json);
          } else resolve(json);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  requestApiReport(methodType, url, body) {
    return new Promise((resolve, reject) => {
      var dataBody = "";
      if (!body) body = {};
      dataBody = JSON.stringify(body);
      this.requestFetch(
        methodType,
        url && url.indexOf("http") == 0 ? url : url,
        {
          Authorization: this.auth,
        },
        dataBody
      )
        .then((s) => {
          s.blob().then((blob) => {
            let blobUrl = URL.createObjectURL(blob);
            resolve(blobUrl);
          });
        })
        .catch((e) => {
          if (e && e.status === 401) {
            localStorage.clear();
            window.location.href = "/dang-nhap";
          }
          reject(e);
        });
    });
  },

  requestApiFiles(methodType, url, body) {
    return new Promise((resolve, reject) => {
      var dataBody = "";
      if (!body) body = {};
      dataBody = JSON.stringify(body);
      this.requestFetch(
        methodType,
        url && url.indexOf("http") == 0 ? url : url,
        {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.auth,
        },
        dataBody
      )
        .then((s) => {
          resolve(s);
        })
        .catch((e) => {
          if (e && e.status === 401) {
            localStorage.clear();
            window.location.href = "/dang-nhap";
          }
          reject(e);
        });
    });
  },
  requestService(url) {
    return new Promise(function (resolve, reject) {
      axios
        .get(server_url + url)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
};
