import client from '../utils/client-utils'
import constants from '../resources/strings'
import datacacheProvider from './datacache-provider'
import clientUtils from '../utils/client-utils'

export default {
  getAccountStorage() {
    var user = datacacheProvider.read('', constants.key.storage.current_account)
    return user
  },
  saveAccountStorage(account) {
    return datacacheProvider.save('', constants.key.storage.current_account, account)
  },
  xx() {
    client.serverApi = ''
  },
  login(username, password) {
    let object = {
      username,
      password: password.toString()
    }
    return new Promise((resolve, reject) => {
      clientUtils.requestApi('post', constants.api.auth, object).then(x => {
        resolve(x)
      }).catch(e => {
        reject(e)
      })
    })
  },
  logout(id) {
    return new Promise((resolve, reject) => {
      clientUtils.requestApi('put', constants.api.user.logout + '/' + id).then(x => {
        resolve(x)
      }).catch(e => {
        reject(e)
      })
    })
  },
  search(param) {
    let parameters =
      (param.page ? '?page=' + param.page : '?page=' + 0) +
      (param.size ? '&size=' + param.size : '&size=' + '') +
      (param.blocked == 0 ? '&blocked=' + param.blocked : (param.blocked && param.blocked != -1) ? '&blocked=' + param.blocked : "") +
      (param.name ? '&name=' + param.name : '') +
      (param.email ? '&email=' + param.email : '') +
      (param.address ? '&address=' + param.address : '') +
      (param.phoneNumber ? '&phoneNumber=' + param.phoneNumber : '') +
      (param.locationName ? '&locationName=' + param.locationName : '') +
      (param.locationId ? '&locationId=' + param.locationId : '') +
      ((param.userType && param.userType !== -1) ? '&userType=' + param.userType : '') +
      ((param.facultyId && param.facultyId !== -1) ? '&facultyId=' + param.facultyId : '') +
      (param.positionName ? '&positionName=' + param.positionName : '') +
      (param.positionId ? '&positionId=' + param.positionId : '') +
      (param.birthDay ? '&birthDay=' + param.birthDay : '') +
      (param.gender ? '&gender=' + param.gender : '') +
      (param.workPlace ? '&workPlace=' + param.workPlace : '') +
      (param.diploma ? '&diploma=' + param.diploma : '') +
      (param.certificateCode ? '&certificateCode=' + param.certificateCode : '') +
      (param.username ? '&username=' + param.username : '') +
      ((param.hospitalId && param.hospitalId !== -1) ? '&hospitalId=' + param.hospitalId : '') +
      (param.createdAt ? '&createdAt=' + param.createdAt : '') +
      (param.createdBy ? '&createdBy=' + param.createdBy : '') +
      (param.hdkh ? '&hdkh=' + param.hdkh : '') +
      (param.hddd ? '&hddd=' + param.hddd : '')
      + '&sort=createdAt,desc'
    return new Promise((resolve, reject) => {
      clientUtils.requestApi('get', constants.api.user + parameters, {}).then(x => {
        resolve(x)
      }).catch(e => {
        reject(e)
      })
    })
  },
  create(object) {
    return new Promise((resolve, reject) => {
      clientUtils.requestApi('post', constants.api.user, object).then(x => {
        resolve(x)
      }).catch(e => {
        reject(e)
      })
    })
  },
  update(id, object) {
    return new Promise((resolve, reject) => {
      clientUtils.requestApi('put', constants.api.user + '/' + id, object).then(x => {
        resolve(x)
      }).catch(e => {
        reject(e)
      })
    })
  },
  delete(id) {
    return new Promise((resolve, reject) => {
      clientUtils.requestApi('delete', constants.api.user + '/' + id).then(x => {
        resolve(x)
      }).catch(e => {
        reject(e)
      })
    })
  },
  block(id) {
    return new Promise((resolve, reject) => {
      clientUtils.requestApi('put', constants.api.user + '/' + id + '/block').then(x => {
        resolve(x)
      }).catch(e => {
        reject(e)
      })
    })
  },
  reset(id) {
    return new Promise((resolve, reject) => {
      clientUtils.requestApi('put', constants.api.user + '/' + id + '/reset-password').then(x => {
        resolve(x)
      }).catch(e => {
        reject(e)
      })
    })
  },
  getDetail(id) {
    return new Promise((resolve, reject) => {
      clientUtils.requestApi('get', constants.api.user + '/' + id).then(x => {
        resolve(x)
      }).catch(e => {
        reject(e)
      })
    })
  },
  updatePassword(id, object) {
    return new Promise((resolve, reject) => {
      clientUtils.requestApi('put', constants.api.user + '/' + id + '/update-password', object).then(x => {
        resolve(x)
      }).catch(e => {
        reject(e)
      })
    })
  },
  updateAvatar(id, object) {
    return new Promise((resolve, reject) => {
      clientUtils.requestApi('put', constants.api.user + '/' + id + '/avatar', object).then(x => {
        resolve(x)
      }).catch(e => {
        reject(e)
      })
    })
  },
  updateEmail(id, object) {
    return new Promise((resolve, reject) => {
      clientUtils.requestApi('put', constants.api.user + '/' + id + '/email', object).then(x => {
        resolve(x)
      }).catch(e => {
        reject(e)
      })
    })
  },
  checkUser(id) {
    return new Promise((resolve, reject) => {
      clientUtils.requestApi('put', constants.api.user + '/' + id + '/check-user').then(x => {
        resolve(x)
      }).catch(e => {
        reject(e)
      })
    })
  },
  sendOtp(email,quenMatKhau){
    let url = constants.api.sendOtp + '?email=' + email;
    url += '&quenMatKhau=' + quenMatKhau;
    return new Promise((resolve, reject) => {
      clientUtils.requestApi('get', url).then(x => {
        resolve(x)
      }).catch(e => {
        reject(e)
      })
    })
  },
  authenOtp(email,otp){
    let url = constants.api.otpAuthen + '?email=' + email;
    url += '&otp=' + otp;
    return new Promise((resolve, reject) => {
      clientUtils.requestApi('get', url).then(x => {
        resolve(x)
      }).catch(e => {
        reject(e)
      })
    })
  },
  forgotPassword(email,password){
    let url = constants.api.forgotPassword + '?email=' + email;
    url += '&newPassword=' +   password;
    return new Promise((resolve, reject) => {
      clientUtils.requestApi('put', url).then(x => {
        resolve(x)
      }).catch(e => {
        reject(e)
      })
    })
  },
  getEmails(){
    return new Promise((resolve, reject) => {
      clientUtils.requestApi('get', constants.api.distinctEmail).then(x => {
        resolve(x)
      }).catch(e => {
        reject(e)
      })
    })
  },
}
