import constants from "../resources/strings";
import clientUtils from "../utils/client-utils";
const defaultState = {
  userApp: {
    currentUser: {},
    image: "",
    isLogin: false,
    loginToken: "",
    email: "",
  },
  tabsKey: "1",
  isLoading: false,
  renderSidebar: false,
};
const reducer = (state = defaultState, action) => {
  var newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case constants.action.action_user_login:
      newState.userApp.currentUser = action.value
        ? action.value
        : newState.userApp.currentUser;
      newState.userApp.isLogin =
        newState.userApp.currentUser && newState.userApp.currentUser.id;
      newState.userApp.loginToken = newState.userApp.currentUser
        ? newState.userApp.currentUser.access_token
        : "";
      clientUtils.auth = newState.userApp.loginToken
        ? "bearer " + newState.userApp.loginToken
        : "";
      newState.userApp.unReadNotificationCount = 0;
      return newState;
    case constants.action.action_user_logout:
      // userProvider.logout();
      newState.userApp.unReadNotificationCount = 0;
      newState.userApp.currentUser = {};
      newState.userApp.isLogin = false;
      newState.userApp.loginToken = "";
      clientUtils.auth = "";
      return newState;
    case constants.action.action_user_user_email:
      newState.userApp.email = action.value;
      return newState;
    case constants.action.update_avatar:
      newState.userApp.currentUser.image = action.value;
      return newState;
    case constants.action.update_notification_count:
      newState.notification_count = action.value;
      return newState;
    case constants.action.update_tabs_key:
      newState.tabsKey = action.value;
      return newState;
    case constants.action.RENDER_SIDEBAR:
      newState.renderSidebar = action.value;
      return newState;
    case constants.action.UPDATE_LOADING:
      newState.isLoading = action.value;
      return newState;
  }
  return newState;
};

export default reducer;
